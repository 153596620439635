import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow, faCheckCircle, faCircleXmark, faX} from '@fortawesome/free-solid-svg-icons';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import './App.css';

async function handleChatRegistration(advisorId, content, sessionId, onClose) {
  try {
    const payload = {
      advisor_id: advisorId,
      content: content,
      session_id: sessionId,
    };

    // Make the API call
    const response = await fetch('/api/chat_registration', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      await response.json();
    } else {
      console.error('API call failed:', response.statusText);
    }
  } catch (error) {
    console.error('Error making API call:', error);
  } finally {
    // Close the modal after the API call
    onClose();
  }
}

// Modal Component
function WelcomeModal({ advisorId, onClose, sessionId, language }) {
  // Language-specific translations
  const translations = {
    en: {
      welcomeTitle: "Welcome to",
      welcomeHeader: "Ultimo Bots",
      question: "Are you ready to create your own chatbot for your business",
      free: "for free",
      time: "It takes less than",
      minutes: "2 minutes",
      yes: "Yes!",
      no: "No, maybe later.",
    },
    de: {
      welcomeTitle: "Willkommen bei",
      welcomeHeader: "Law22",
      question: "Möchten Sie jemanden kostenfrei verklagen?",
      free: "kostenlos",
      time: "Fordern Sie jetzt Ihr Recht ein",
      minutes: "in 2 Minuten",
      yes: "Ja!",
      no: "Nein.",
    },
  };

  // Select the appropriate translations
  const t = translations[language] || translations.de;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className='close-button-container'>
          <button
            className="close-button"
            onClick={() => handleChatRegistration(advisorId, 'Closed', sessionId, onClose)}
          >
            <div className="option-content">
              <FontAwesomeIcon icon={faX} className="close-icon" />
            </div>
          </button>
        </div>
        <h3>{t.welcomeTitle}</h3>
        <img src={'../law24_logo_all_white.png'} alt="Logo" className='logo-modal' />
        <p>
          {t.question}
        </p>
        {/* <p>
          {t.time} <strong><u>{t.minutes}</u></strong>!
        </p> */}
        <div className="options">
          <button
            className="modal-option-button"
            onClick={() => handleChatRegistration(advisorId, 'Agreed', sessionId, onClose)}
          >
            <div className="option-content">
              <FontAwesomeIcon icon={faCheckCircle} className="agree-icon" />
              {t.yes}
            </div>
          </button>
          <button
            className="modal-option-button"
            onClick={() => handleChatRegistration(advisorId, 'Declined', sessionId, onClose)}
          >
            <div className="option-content">
              <FontAwesomeIcon icon={faCircleXmark} className="decline-icon" />
              {t.no}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

function Chat() {
  const typingSpeed = 8;
  const { botId, language } = useParams();
  const [userInput, setUserInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const [advisorId, setAdvisorId] = useState('');
  const [showModal, setShowModal] = useState(true);
  const messagesEndRef = useRef(null);
  const currentBotMessage = useRef("");
  const [showUserInfoForm, setShowUserInfoForm] = useState(false);
  const [firstMessageSent, setFirstMessageSent] = useState(true);
  const [conversionTracked, setConversionTracked] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const generateSessionId = () => {
      const timestamp = new Date().getTime();
      const randomNum = Math.floor(Math.random() * 10000) + 1;
      return `${timestamp}-${randomNum}`;
    };
    setSessionId(generateSessionId());
  }, []);

  useEffect(() => {
    const advisorIds = {
      de: 'law-agent-1',
      // de: 'law-agent-1',
    };
    setAdvisorId(advisorIds[language] || 'law-agent-1');
    if (sessionId) {
      handleChatRegistration(advisorId, 'Page loaded', sessionId, () => {});
    }
  }, [sessionId, language, advisorId]);

  useEffect(() => {
    if (!showModal) {
      let timeoutId;
      const fetchWelcomeMessage = async () => {
        try {
          const welcomeMessages = {
            en: `Ab jetzt und in der Zukunft brauchen Sie sich nichts mehr gefallen zu lassen. **Wir helfen Ihnen.** Haben Sie ein bestimmtes rechtliches Anliegen, bei dem wir Ihnen aktuell helfen können? ⚖️`,
            de: `Ab jetzt und in der Zukunft brauchen Sie sich nichts mehr gefallen zu lassen. **Wir helfen Ihnen.** Haben Sie ein bestimmtes rechtliches Anliegen, bei dem wir Ihnen aktuell helfen können? ⚖️`,
          };
          const fullWelcomeMessage = welcomeMessages[language] || welcomeMessages.en;
          let index = 0;
          let displayedWelcomeMessage = '';

          setMessages([
            {
              text: '',
              from: 'bot'
            }
          ]);

          const typeNextCharacter = () => {
            if (index < fullWelcomeMessage.length) {
              const nextChar = fullWelcomeMessage[index];
              index++;
              displayedWelcomeMessage += nextChar;
              setMessages(messages => {
                const newMessages = [...messages];
                newMessages[newMessages.length - 1] = {
                  ...newMessages[newMessages.length - 1],
                  text: displayedWelcomeMessage
                };
                return newMessages;
              });
              timeoutId = setTimeout(typeNextCharacter, typingSpeed);
            } else {
              clearTimeout(timeoutId);
            }
          };

          typeNextCharacter();
        } catch (error) {
          console.error('Error fetching welcome message:', error);
          setMessages([
            {
              text: "Failed to load welcome message from server.",
              from: 'bot'
            }
          ]);
        }
      };

      fetchWelcomeMessage();

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [botId, typingSpeed, showModal, language]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const sendMessage = async (customMessage = null, skipUserMessage = false) => {
    const messageToSend = customMessage || userInput;
    if (!firstMessageSent) {
      setFirstMessageSent(true);
      const userMessage = { text: messageToSend, from: 'user' };
      const botResponse = {
        text: "Before we continue, I'll need some information from you. Could you please provide your name and email address?",
        from: 'bot',
        showUserInfoForm: true
      };
      setMessages(messages => [...messages, userMessage, botResponse]);
      setUserInput('');
      setShowUserInfoForm(true);
      return;
    }

    if (!skipUserMessage) {
      const userMessage = { text: messageToSend, from: 'user' };
      setMessages(messages => [...messages, userMessage]);
    }

    setUserInput('');
    setLoading(true);
    currentBotMessage.current = "";

    const maxRetries = 3;
    let retryCount = 0;

    const attemptConnection = () => {
      return new Promise((resolve, reject) => {
        const eventSource = new EventSource(`/api/chat_response?user_input=${encodeURIComponent(messageToSend)}&session_id=${sessionId}&advisor_id=${advisorId}`);

        let isFirstMessage = true;
        let timeoutId;

        eventSource.onmessage = (event) => {
          const chunk = event.data;
          if (chunk !== 'end of response') {
            if (isFirstMessage) {
              setLoading(false);
              isFirstMessage = false;
              clearTimeout(timeoutId);
            }
            const parsedChunk = chunk.replace(/<newline>/g, '\n');
            currentBotMessage.current += parsedChunk;
            setMessages(messages => {
              const lastMessage = messages[messages.length - 1];
              if (lastMessage && lastMessage.from === 'bot') {
                return [...messages.slice(0, -1), { ...lastMessage, text: currentBotMessage.current }];
              } else {
                return [...messages, { text: currentBotMessage.current, from: 'bot' }];
              }
            });
          }
        };

        eventSource.onerror = (error) => {
          console.error('Error fetching response:', error);
          if (isFirstMessage) {
            reject(new Error('Failed to get response from server.'));
          }
          eventSource.close();
        };

        eventSource.addEventListener('end', () => {
          setMessages(messages => {
            const lastMessage = messages[messages.length - 1];
            if (lastMessage && lastMessage.from === 'bot') {
              return [...messages.slice(0, -1), { ...lastMessage, text: currentBotMessage.current }];
            } else {
              return [...messages, { text: currentBotMessage.current, from: 'bot' }];
            }
          });
          eventSource.close();
          resolve();
        });

        timeoutId = setTimeout(() => {
          if (isFirstMessage) {
            eventSource.close();
            reject(new Error('No response received from server.'));
          }
        }, 30000);
      });
    };

    const retryConnection = async () => {
      while (retryCount < maxRetries) {
        try {
          await attemptConnection();
          return;
        } catch (error) {
          retryCount++;
          console.log(`Attempt ${retryCount} failed. Retrying...`);
          if (retryCount >= maxRetries) {
            setLoading(false);
            setMessages(messages => [...messages, { text: `Failed to get response after ${maxRetries} attempts.`, from: 'bot' }]);
            throw error;
          }
        }
      }
    };

    try {
      await retryConnection();
    } catch (error) {
      console.error('Error fetching response:', error);
      setLoading(false);
    }
  };

  const LinkRenderer = (props) => {
    return (
      <a href={props.href} target="_blank" rel="noopener noreferrer">
        {props.children}
      </a>
    );
  };

  const gtag_report_conversion = (url) => {
    const callback = () => {
      if (typeof url !== 'undefined') {
        window.location = url;
      }
    };

    if (typeof window.gtag === 'function') {
      window.gtag('event', 'conversion', {
        'send_to': 'AW-430840170/mo-5CMbU5OsZEOqyuM0B',
        'value': 1.0,
        'currency': 'CHF',
        'event_callback': callback,
      });
    } else {
      console.warn('gtag is not defined');
    }
    return false;
  };


  return (
    <div className="chat-container">
      {showModal && <WelcomeModal advisorId={advisorId} onClose={closeModal} sessionId={sessionId} language={language}/>}
      <div className="chat-parent">
        <div className='logo-messages-container'>
          <div className='logo-container'>
            <img src={'../law24_logo.png'} alt="Logo" className='logo' />
          </div>
          <div className="message-container">
            {messages.map((msg, index) => (
              <div key={index} className={`message ${msg.from === 'user' ? 'user-message' : 'bot-message'}`}>
                {msg.from === 'bot' && (
                  <div className="ultimo-bots-icon-container">
                    <img src="../law24-icon.png" alt="Bot Icon" className='ultimo-bots-icon' />
                  </div>
                )}
                <div className={msg.from === 'user' ? 'user-chat-text' : 'bot-chat-text'}>
                  <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}
                    components={{
                      a: LinkRenderer
                    }}
                  >
                    {msg.text}
                  </ReactMarkdown>
                </div>
                {msg.from === 'user' && (
                  <div className="user-icon-container">
                    <img src="../ultimo-user-icon.png" alt="User Icon" className='user-icon' />
                  </div>
                )}
              </div>
            ))}
            {loading && <div className="loader"></div>}
            <div ref={messagesEndRef} />
          </div>
        </div>
        <div className="chat-input">
          <input
            type="text"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && !showUserInfoForm && userInput.trim() ? sendMessage() : null}
            disabled={showUserInfoForm}
            placeholder={showUserInfoForm ? "Please fill out the form above to continue" : "Nachricht eingeben..."}
          />
          <button
            onClick={() => {
              if (userInput.trim() && !showUserInfoForm) {
                // Nur beim ersten Mal tracken
                if (!conversionTracked) {
                  gtag_report_conversion();
                  setConversionTracked(true); // Conversion wurde getrackt
                }
                sendMessage();
              }
            }}
            disabled={showUserInfoForm || !userInput.trim()}
          >
            <FontAwesomeIcon icon={faLocationArrow} className='send-icon' />
          </button>
        </div>
      </div>
    </div>
  );
}

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Chat />} />
        {/* <Route path="/" element={<RedirectToExternal />} /> */}
      </Routes>
    </Router>
  );
}
